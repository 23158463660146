<template>
  <app-relation
    type="ADD"
    :show-back-button="true"
    :relation-data="relationData"
    :show-overlay="showRelationOverlay"
    :handle-button-spinner="relationHandleButtonSpinner"
    handle-button-text="Aanmaken"
    @handle-relation="handleRelation($event)"
  />
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import 'flatpickr/dist/flatpickr.css'
import router from '@/router'
import store from '@/store'
import relationsStoreModule from '@/views/pages/relations/relationsStoreModule'
import formValidation from '@core/comp-functions/forms/form-validation'
import { todayMinYears } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import AppRelation from '@core/components/app-relation/AppRelation.vue'

export default {
  components: {
    AppRelation,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showRelationOverlay: false,
      relationHandleButtonSpinner: false,
    }
  },
  methods: {
    handleRelation(data) {
      this.relationHandleButtonSpinner = true
      store.dispatch('apps-relations-add/addRelation', data)
        .then(() => {
          this.relationHandleButtonSpinner = false
          router.push({ name: 'apps-relations-list' })
        })
        .catch(() => {
          this.relationHandleButtonSpinner = false
          this.$swal({
            icon: 'error',
            title: 'Relatie!',
            text: 'Er is een fout opgetreden bij het aanmaken van de relatie. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const blankRelationData = {
      bankCountry: null,
      bankCurrency: null,
      bankName: null,
      bankNumber: null,
      birthCountry: null,
      birthDate: null,
      birthPlace: null,
      city: null,
      companyActivity: null,
      companyBaseNumber: null,
      companyEstablishmentCountry: null,
      companyEstablishmentDate: null,
      companyFoundationRegisterNumber: null,
      companyKKFNumber: null,
      companyLegalForm: null,
      companyName: null,
      companyTaxNumber: null,
      country: null,
      district: null,
      documentStatus: null,
      email: null,
      files: [],
      firstName: null,
      gender: null,
      houseNumber: null,
      id: null,
      idExpiryDate: null,
      idIssuingCountry: null,
      idNumber: null,
      idType: null,
      isIssueDate: null,
      isPEP: null,
      isResident: null,
      lastName: null,
      maidenMiddleName: null,
      maidenName: null,
      maritalStatus: null,
      middleName: null,
      mobile: null,
      nationality: null,
      occupation: null,
      occupationSector: null,
      phone: null,
      riskProfile: null,
      street: null,
      type: 1,
      whatsappPhone: null,
    }

    const relationData = ref(JSON.parse(JSON.stringify(blankRelationData)))
    const resetRelationData = () => {
      relationData.value = JSON.parse(JSON.stringify(blankRelationData))
    }

    const RELATION_APP_STORE_MODULE_NAME = 'apps-relations-add'

    // Register module
    if (!store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.registerModule(RELATION_APP_STORE_MODULE_NAME, relationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.unregisterModule(RELATION_APP_STORE_MODULE_NAME)
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRelationData)

    return {
      relationData,
      refFormObserver,
      getValidationState,
      resetForm,
      todayMinYears,
    }
  },
}
</script>
